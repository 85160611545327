import { computed, type Ref, ref } from 'vue'
import { defineStore } from 'pinia'

export const useNotificationsStore = defineStore('notifications', () => {
  const unreadNotifications: Ref<NotificationData | []> = ref([]);

  const getUnreadNotifications = computed(() => unreadNotifications.value);

  return { unreadNotifications, getUnreadNotifications }
});
